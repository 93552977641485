.ri4vy6w{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:clamp(50%, 700px, 90%);gap:1rem;}
.hmc6r59{-webkit-align-self:stretch;-ms-flex-item-align:stretch;-ms-grid-row-align:stretch;align-self:stretch;width:100%;border-radius:0.75rem;text-align:center;height:90px;padding:20px;background-image:var(--gradient);}.hmc6r59 img{max-height:100%;margin:auto;display:block;}
.t17ti1t5{font-size:24px;font-weight:800;}
.sap8tsq{font-weight:600;color:#C3CBD9;}
.r7an3x2{--gradient:linear-gradient(to left, #c4c4c4, #979797);}
.c1p3428q{--gradient:linear-gradient(to left, #5feb6e, #1ed6ae);}
.i192lyi9{--gradient:linear-gradient(to left, #00ccf8, #5530ff);}
.u3arahr{--gradient:linear-gradient(to left, #c55bff, #5800dd);}
.sludtxx{--gradient:linear-gradient(to left, #fac92b, #fa8e24);}
.l153k1ec{list-style:var(--gradient);}
